import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Kammuikee.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * }} props
 */
export default function Kammuikee (props) {
  const { readMore, onClick } = props

  if (!readMore) {
    return (
      <>
        <p>
          金妹記保存了舊式大排檔的味道，同時馳名於香港地道風味、鑊氣小炒，最適合三五知己在大排檔舉杯暢飲。適逢今年餐廳推出了六位用的新年盆菜，特色之處...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </>
    )
  }

  return (
    <>
      <p>
        金妹記保存了舊式大排檔的味道，同時馳名於香港地道風味、鑊氣小炒，最適合三五知己在大排檔舉杯暢飲。適逢今年餐廳推出了六位用的新年盆菜，特色之處在於餐廳使用了砂煲，可供客人明火翻熱，風味十足。同時亦可以轉成金屬鍋，兩種煲可讓客人隨意選擇，同時選用了新鮮本地白切雞，本地新鮮現撈海蝦，原隻鮑魚等食材，讓客人可以團圓渡過迎接牛年。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </>
  )
}
