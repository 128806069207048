import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

Title.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.string,
}

/**
 * @component
 * @param {{
 * size: 'small' | 'medium' | 'large'
 * title: string
 * }} props
 */
export default function Title (props) {
  const classes = useStyles(props)
  const { size, title } = props

  return (
    <Typography
      variant='h4'
      className={clsx(
        classes.title,
        size === 'small' && classes.titleSizeSmall,
        size === 'medium' && classes.titleSizeMedium,
        size === 'large' && classes.titleSizeLarge,
      )}
    >
      {title}
    </Typography>
  )
}
const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    color: colors.black1,
  },
  titleSizeSmall: {
    fontSize: '2.2rem',
  },
  titleSizeMedium: {
    fontSize: '2.4rem',
  },
  titleSizeLarge: {
    fontSize: '2.4rem',
  },
}))
