
import constants from '@/constants'
import colors from '@/theme/colors'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Links from './Links'
import Logo from './Logo'

/**
 * @component
 * @param {*} props
 */
export default function Footer (props) {
  const classes = useStyles(props)

  return (
    <div className={classes.footer}>
      <Container
        className={classes.container}
        classes={{ maxWidthLg: classes.maxWidthLg }}
      >
        <Logo />
        <Links />
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  footer: {
    boxSizing: 'border-box',
    marginTop: 120,
    padding: 8,
    backgroundColor: colors.primary,
  },
  container: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: constants.app.height['FOOTER/MIN_HEIGHT'],
  },
  maxWidthLg: {
    maxWidth: constants.app.width['CONTAINER/MAX_WIDTH'],
  },
}))
