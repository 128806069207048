import Banner from '@/components/Banner'
import Content from '@/components/Content'
import NavBar from '@/components/NavBar'
import NavIconButton from '@/components/NavBar/NavIconButton'
import constants from '@/constants'
import BookmarkDialog from '@/dialogs/Bookmark'
import PrivacyPolicyDialog from '@/dialogs/PrivacyPolicy'
import ShareDialog from '@/dialogs/Share'
import TermsAndConditionsDialog from '@/dialogs/TermsAndConditions'
import useBanners from '@/hooks/useBanners'
import FilterDialog from '@/pages/FilterDialogPage'
import FilterResultDialogPage from '@/pages/FilterResultDialogPage'
import FindMoreDialogPage from '@/pages/FindMoreDialogPage'
import SearchDialog from '@/pages/SearchDialogPage'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import Div100vh from 'react-div-100vh'
import Category from './Category'
import Footer from './Footer'

/**
 * @component
 * @param {*} props
 */
export default function Landing (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const restaurants = useSelector(state => state.app.restaurants)
  const banners = useBanners()

  const goToPage = (page) => {
    dispatch(actions.app.resetPages())
    dispatch(actions.app.togglePage(page, true))
  }
  const userAgent = window.navigator.userAgent
  const showbtn = ((/iphone|ipod|ipad/i).test(userAgent) || /android/i.test(userAgent)) && !window.matchMedia('(display-mode: standalone)').matches
  return (
    <Div100vh className={classes.container}>
      <NavBar
        logo
        startButtons={[
          <NavIconButton key='Landing-start-0' variant='share' onClick={() => dispatch(actions.app.toggleDialog('share'))} />,
          showbtn
            ? (
              <NavIconButton key='Landing-start-1' variant='bookmark' onClick={() => dispatch(actions.app.toggleDialog('bookmark'))} />
            )
            : null,
        ]}
        endButtons={[
          <NavIconButton key='Landing-end-0' variant='search' onClick={() => goToPage('search')} />,
          <NavIconButton key='Landing-end-1' variant='filter' onClick={() => goToPage('filter')} />,
        ]}
      />
      <Content>
        <Banner banners={banners} />
        {_.map(constants.categories, (category, index) => {
          return (
            <Category
              key={index}
              size={category.size}
              title={category.name}
              link={category.link}
              list={_.filter(restaurants, r => _.includes(r.tags, category.name))}
            />
          )
        })}
      </Content>
      <Footer />

      <SearchDialog />
      <FilterDialog />
      <ShareDialog />
      <BookmarkDialog />
      <FilterResultDialogPage />
      <FindMoreDialogPage />
      <TermsAndConditionsDialog />
      <PrivacyPolicyDialog />
    </Div100vh>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
