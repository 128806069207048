import Content from '@/components/Content'
import FullpageDialog from '@/components/FullpageDialog'
import NavBar from '@/components/NavBar'
import NavButton from '@/components/NavBar/NavButton'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'

/**
 * @component
 * @param {*} props
 */
export default function SearchDialogPage (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.pages.search)
  const togglePage = () => {
    dispatch(actions.app.togglePage('search'))
    dispatch(actions.app.updateSearchInput(''))
  }

  return (
    <FullpageDialog open={open} onClose={togglePage}>
      <div className={classes.container}>
        <NavBar
          title='搜尋'
          startButtons={[<NavButton key='SearchDialogPage-start-0' variant='back' onClick={togglePage} />]}
        />
        <Content>
          <SearchBar />
          <SearchResult />
        </Content>
      </div>
    </FullpageDialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))
