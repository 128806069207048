import ArrowBackIcon from '@/assets/icons/custom/ArrowBack'
import colors from '@/theme/colors'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

NavButton.propTypes = {
  variant: PropTypes.oneOf(['back', 'cancel']),
}

/**
 * @component
 * @param {{
 * variant: 'back' | 'cancel'
 * }} props
 */
export default function NavButton (props) {
  const { variant, onClick } = props
  const classes = useStyles(props)

  let text, icon
  switch (variant) {
    case 'back': {
      text = '返回'
      icon = <ArrowBackIcon stroke={colors.gray1} />
      break
    }
    case 'cancel': {
      text = '取消'
      icon = <ArrowBackIcon stroke={colors.gray1} />
      break
    }
    default:
      break
  }

  return (
    <Button
      size='small'
      variant='text'
      className={classes.button}
      classes={{ iconSizeSmall: classes.icon }}
      startIcon={icon}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: '1.6rem',
    fontWeight: 300,
    padding: 8,
  },
  icon: {
    '& :first-child': {
      fontSize: '1.6rem',
    },
  },
}))
