import _ from 'lodash'

/**
 * @function
 * @param {string} text
 * @returns {string}
 */
export function toUpperCase (text) {
  if (typeof text === 'string') {
    return text.toUpperCase()
  } else {
    throw new Error('Wrong type. Type must be string.')
  }
}

/**
 * @function
 * @param {string} text
 * @param {string} query
 * @param {{caseSensitive: boolean}} options
 * @returns {string}
 */
export function matchHighlight (text, query, options) {
  if (typeof text === 'string' && typeof query === 'string') {
  // g 找出全部，i 不分大小寫
    const regexp = new RegExp(query, options?.caseSensitive ? 'g' : 'gi')

    // 找出 text 中全部符合 regexp 的字
    const matches = _.flatten([...text.matchAll(regexp)])
    const matchesHighlights = _.map(matches, text => ({ text, highlight: true }))

    // 將 text 用不分大小寫的 query 拆開
    const others = text.split(regexp)
    const othersHighlights = _.map(others, text => ({ text, highlight: false }))

    // others 的數量一定會比 matches 多 1 個
    // [ other, match, other, match, other ] 依序放回去
    const parts = []
    _.forEach(othersHighlights, (othersHighlight, index) => {
      parts.push(othersHighlight)
      if (matchesHighlights[index]) {
        parts.push(matchesHighlights[index])
      }
    })
    return parts
  } else {
    throw new Error('Wrong type. Type must be string.')
  }
}

/**
 * 擷取字串(通過substring實現並支援中英文混合)
 * @param {string} str
 * @param {number} n 需要擷取的長度
 * @returns {string}
 */
export function substring (str, n) {
  // eslint-disable-next-line no-control-regex
  const r = /[^\x00-\xff]/g
  if (str.replace(r, '**').length <= n) {
    return str
  }
  const m = Math.floor(n / 2)
  for (let i = m; i < str.length; i++) {
    if (str.substr(0, i).replace(r, '**').length >= n) {
      return str.substr(0, i) + '...'
    }
  } return str
}

/**
 * @param {string} str
 * @returns {number}
 */
export function getLength (str) {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[^\x00-\xff]/g, '**').length
}

/**
 * 數字加千位數分隔逗號
 * @param {number} n
 * @returns {string}
 */
export function numberWithCommas (n) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
