import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Serwongfun.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Serwongfun (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          屹立於中環超過一個世紀的蛇王芬，今年繼續堅持傳統，推出四位用「蛇王芬」聚寶大盆菜($1688)，食材選用蛇王芬馳名蠟腸及膶腸、澳洲六頭鮑魚、日本元貝...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        屹立於中環超過一個世紀的蛇王芬，今年繼續堅持傳統，推出四位用「蛇王芬」聚寶大盆菜($1688)，食材選用蛇王芬馳名蠟腸及膶腸、澳洲六頭鮑魚、日本元貝等、保證食過翻尋味，慶祝家人團團圓圓，開開心心迎接新一年。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
