import FirecrackerIcon from '@/campaigns/cny2021/assets/icons/firecracker.svg'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Section from './Section'
import Title from './Title'

Description.propTypes = {
  data: PropTypes.object,
}

/**
 * @component
 * @param {{
 * data: object
 * }} props
 */
export default function Description (props) {
  const { data } = props
  const classes = useStyles(props)
  const [readMore, setReadMore] = useState(false)
  const Content = data.desc

  return (
    <Section>
      <Title title='預訂盆菜' subtitle={data.reservation} icon={<img src={FirecrackerIcon} />} />
      <Content className={classes.desc} readMore={readMore} onClick={() => setReadMore(!readMore)} />
    </Section>
  )
}

const useStyles = makeStyles(theme => ({
  desc: {
    color: '#383838',
    fontSize: 'inherit',
    '& > :first-child': {
      marginTop: 4,
    },
    '& > :last-child': {
      marginBottom: 4,
    },
  },
}))
