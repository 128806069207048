import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Tsochoi.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Tsochoi (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          粗菜館使用真材實料炮製聚寶盆菜，使用上各款矜貴食材，原隻鮑魚、一品瑤柱甫、頂級髮菜、白切龍崗雞等。現時四位用只需$888 六位用$1088，連食評家蔡...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        粗菜館使用真材實料炮製聚寶盆菜，使用上各款矜貴食材，原隻鮑魚、一品瑤柱甫、頂級髮菜、白切龍崗雞等。現時四位用只需$888 六位用$1088，連食評家蔡瀾都誠意推介，點到你唔試！
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
