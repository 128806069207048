import { actions, useDispatch } from '@/redux'

const defaultSize = {
  width: 1024,
  height: 399,
}

export default function useBanners () {
  const dispatch = useDispatch()

  return [
    // {
    //   image: {
    //     url: 'https://img.dimorder.com/1024x/public/Landing_Banner/20211208_banner_chrismas-01.png',
    //     ...defaultSize, // 算 aspect ratio 用的
    //   },
    //   onClick: () => {
    //     // window.open('https://www.facebook.com/DimOrder/photos/a.2854965024584148/4477370552343579/')
    //   },
    // },
    // {
    //   image: {
    //     url: 'https://img.dimorder.com/1024x/public/Landing_Banner/20220104_banner_1%E6%9C%88delivery%20fee-01.png',
    //     ...defaultSize, // 算 aspect ratio 用的
    //   },
    //   onClick: () => {
    //     // window.open('https://s.dimorder.com/facebookappbanner')
    //   },
    // },
    {
      image: {
        url: 'https://img.dimorder.com/1024x/public/Landing_Banner/20211026_banner_%E7%B1%B3%E8%8A%9D%E8%93%AE%20%281%29.png',
        ...defaultSize, // 算 aspect ratio 用的
      },
      onClick: () => {
        dispatch(actions.app.resetSelectedFilters())
        dispatch(
          actions.app.updateFilterMultipleOptions('options', '米芝蓮', true),
        )
        dispatch(actions.app.togglePage('filterResult'))
      },
    },
  ]
}
