import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function Links (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()

  const redirectTo = (url) => window.open(url, '__blank')
  const handleOpenTerms = () => dispatch(actions.app.toggleDialog('termsAndConditions', true))
  const handleOpenPrivacy = () => dispatch(actions.app.toggleDialog('privacyPolicy', true))

  return (
    <div className={classes.container}>
      <Typography variant='h5' className={classes.text}>
        <Link color='inherit' underline='hover' onClick={() => redirectTo('https://www.dimorder.com/aboutus/#contact')}>餐廳加盟</Link>
      </Typography>
      <Typography variant='h5' className={classes.text}>
        <Link color='inherit' underline='hover' onClick={handleOpenTerms}>條款及細則</Link>
      </Typography>
      <Typography variant='h5' className={classes.text}>
        <Link color='inherit' underline='hover' onClick={handleOpenPrivacy}>私隱政策</Link>
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 16,
  },
  text: {
    padding: 8,
    color: colors.white,
    fontWeight: 300,
    cursor: 'pointer',
  },
}))
