import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function Search (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.3 7.03331C1.3 3.85538 3.82537 1.3 6.9151 1.3C10.0048 1.3 12.5302 3.85538 12.5302 7.03331C12.5302 8.54901 11.9557 9.92309 11.0196 10.9459L10.5835 11.3738C9.59789 12.2425 8.31437 12.7666 6.9151 12.7666C3.82537 12.7666 1.3 10.2112 1.3 7.03331ZM11.3233 12.4528C10.1281 13.4601 8.5932 14.0666 6.9151 14.0666C3.08459 14.0666 0 10.9062 0 7.03331C0 3.16043 3.08459 0 6.9151 0C10.7456 0 13.8302 3.16043 13.8302 7.03331C13.8302 8.73878 13.232 10.3061 12.2354 11.5262L15.2107 14.5576L15.666 15.0215L14.7382 15.9321L14.2829 15.4682L11.3233 12.4528Z' />
    </SvgIcon>
  )
}
