import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Tszmui.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Tszmui (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          在市場上有着滿滿的新式盤菜的時代，慈妹大排檔於今年推出慈妹傳統盤菜，重點推介明爐燒鴨，貴妃雞，鮑汁豬手。盆菜共有兩個份量的選擇，適合各大小家...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        在市場上有着滿滿的新式盤菜的時代，慈妹大排檔於今年推出慈妹傳統盤菜，重點推介明爐燒鴨，貴妃雞，鮑汁豬手。盆菜共有兩個份量的選擇，適合各大小家庭，圍在一桌，食着盤菜，說着新年願望，定能讓人回味無窮。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
