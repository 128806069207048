import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  text: PropTypes.string,
}

/**
 * @component
 * @param {{
 * onClick: Function
 * children?: any
 * text?: string
 * }} props
 */
export default function SubmitButton (props) {
  const { onClick, children, text } = props
  const classes = useStyles(props)

  return (
    <ButtonBase
      TouchRippleProps={{ classes: { child: classes.rippleClasses } }}
      className={classes.submitButton}
      onClick={onClick}
    >
      <Typography className={classes.buttonText}>
        {text || children || '繼續'}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(theme => ({
  submitButton: {
    width: '100%',
    backgroundColor: '#c20000',
    borderRadius: 10,
    padding: 6,
    boxShadow: '3px -3px 0px #7b0000',
  },
  buttonText: {
    fontSize: '1.6rem',
    fontWeight: 500,
    color: '#ffffff',
  },
  rippleClasses: {
    backgroundColor: '#7b0000',
  },
}))
