import FoldingfanIcon from '@/campaigns/cny2021/assets/icons/folding-fan.svg'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Section from './Section'
import SubmitButton from './SubmitButton'
import Title from './Title'
Datetime.propTypes = {
  link: PropTypes.string,
}

// const dates = [
//   moment().format('YYYY年MM月DD日'),
//   moment().add(1, 'day').format('YYYY年MM月DD日'),
//   moment().add(2, 'day').format('YYYY年MM月DD日'),
//   moment().add(3, 'day').format('YYYY年MM月DD日'),
//   moment().add(4, 'day').format('YYYY年MM月DD日'),
// ]

// const times = [
//   moment().format('hh時mm分a'),
//   moment().add(1, 'hour').format('hh時mm分a'),
//   moment().add(2, 'hour').format('hh時mm分a'),
//   moment().add(3, 'hour').format('hh時mm分a'),
//   moment().add(4, 'hour').format('hh時mm分a'),
// ]

/**
 * @component
 * @param {{
 * link: string
 * }} props
 */
export default function Datetime (props) {
  const { link } = props
  const classes = useStyles(props)
  // const [date, setDate] = useState(dates[0])
  // const [time, setTime] = useState(times[0])
  const handleSubmit = () => window.open(link, '_blank')

  return (
    <Section>
      <Title title='預訂盆菜' icon={<img src={FoldingfanIcon} />} />
      <div className={classes.wrap}>
        {/* <Select label='日期' value={date} options={dates} onChange={(e) => setDate(e.target.value)} />
        <Select label='時間' value={time} options={times} onChange={(e) => setTime(e.target.value)} /> */}
        <SubmitButton text='現在預訂' onClick={handleSubmit} />
      </div>
    </Section>
  )
}

const useStyles = makeStyles(theme => ({
  wrap: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: '8px 0px',
    },
    '& > :last-child': {
      marginBottom: 24,
    },
  },
}))
