import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function ArrowForwardSmall (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 5 8' htmlColor='#ffffff00'>
      <path d='M1 7L4 4L1 1' stroke={strokecolor} strokeWidth='1.3' strokeLinecap='square' strokeLinejoin='round' />
    </SvgIcon>
  )
}
