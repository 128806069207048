import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function Check (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 18 13' htmlColor='#ffffff00'>
      <path d='M17 1L6 12L1 7' stroke={strokecolor} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  )
}
