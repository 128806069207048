import { transformation } from '@/libs/cloudinary'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

Hero.propTypes = {
  image: PropTypes.string,
}

/**
 * @component
 * @param {{
 * image: string
 * }} props
 */
export default function Hero (props) {
  const { image } = props
  const classes = useStyles({ ...props, image })

  return (
    <figure className={classes.hero} />
  )
}

const useStyles = makeStyles(theme => ({
  hero: {
    paddingTop: `${211 / 375 * 100}%`,
    backgroundColor: 'gray',
    borderRadius: 20,
    marginTop: 24,
    margin: 12,
    boxShadow: '6px -6px 0px #C20000',
    backgroundImage: props => {
      const url = transformation(props.image, 'w_1024')
      return `url(${url})`
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}))
