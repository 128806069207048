import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Kamlok.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Kamlok (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          餐廳以雞煲及魚鍋聞名，今年推出了新春富貴盆菜，有三至四位用 ($788)，五至六位用 ($1188)，以及八至十位用 ($1488)。盆菜包括了鮑汁花膠，蠔皇鮑魚...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        餐廳以雞煲及魚鍋聞名，今年推出了新春富貴盆菜，有三至四位用 ($788)，五至六位用 ($1188)，以及八至十位用 ($1488)。盆菜包括了鮑汁花膠，蠔皇鮑魚，原粒瑤柱，日本花菇等精選食材，隨盆菜附送啤酒，實行在牛年來臨前「牛來運轉」。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
