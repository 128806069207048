import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function Search (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 14 14'>
      <path fillRule='evenodd' clipRule='evenodd' d='M13.4597 1.37886L13.9193 0.919239L13 0L12.5404 0.459619L7.00005 6L1.45967 0.459619L1.00005 0L0.0808105 0.919239L0.54043 1.37886L6.08081 6.91924L0.54043 12.4596L0.0808105 12.9192L1.00005 13.8385L1.45967 13.3789L7.00005 7.83848L12.5404 13.3789L13 13.8385L13.9193 12.9192L13.4597 12.4596L7.91929 6.91924L13.4597 1.37886Z' />
    </SvgIcon>
  )
}
