import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Threevirtues.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Threevirtues (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          素食近年是一大流行趨勢，有見及此，三德素食推出了「新春素盆菜」 廚師團隊精心搜羅多達18款矜貴食材，重點推介包括吉品素鮑魚、紅燒獅子頭、素翅、煎...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        素食近年是一大流行趨勢，有見及此，三德素食推出了「新春素盆菜」 廚師團隊精心搜羅多達18款矜貴食材，重點推介包括吉品素鮑魚、紅燒獅子頭、素翅、煎油鴨片等菜式，讓素食的味度不再單調。盆菜共有兩個份量，六人（$688) 及十至十二人( $1288) 每款盆菜更敬送五柳仙班一條。新春佳節，一家大細聚首一堂，吃着素食，開始一個健康的新一年。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
