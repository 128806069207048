import { ReactComponent as CircularLoading } from '@/assets/icons/circular-loading.svg'
import constants from '@/constants'
import { Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

BackdropLoading.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * open: boolean
 * onClick: Function
 * }} props
 */
export default function BackdropLoading (props) {
  const { open, onClick } = props
  const classes = useStyles(props)

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClick}>
      <CircularLoading className={classes.indicator} />
    </Backdrop>
  )
}

const useStyles = makeStyles((theme, props) => ({
  backdrop: {
    zIndex: constants.app.zIndex.BACKDROP_LOADING,
  },
  '@keyframes rotate': {
    from: { transfrom: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  indicator: {
    animationName: '$rotate',
    animation: '800ms infinite linear',
    width: 80,
    height: 80,
  },
}))
