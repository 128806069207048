import api from '@/config/api'
import constants from '@/constants'
import axios from 'axios'
import _ from 'lodash'
import ActionTypes from './ActionTypes'

/**
 * @returns {ThunkFunction}
 */
export function init () {
  return async (dispatch) => {
    dispatch(updateLoading(true))
    await dispatch(fetchRestaurants())
    dispatch(updateFilters())
    dispatch(updateLoading(false))
    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {boolean} isLoading
 */
export function updateLoading (isLoading) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_LOADING,
      payload: { isLoading },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} dialog
 * @param {boolean} open
 */
export function toggleDialog (dialog, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_DIALOG,
      payload: { dialog, open },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetDialogs () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_DIALOGS,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} page
 * @param {boolean} open
 */
export function togglePage (page, open) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.TOGGLE_PAGE,
      payload: { page, open },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function resetPages () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_PAGES,
      payload: {},
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function fetchRestaurants () {
  return async (dispatch) => {
    const res = await axios.get(api.baseUrl)
    const restaurants = res.data.data
    dispatch({
      type: ActionTypes.FETCH_RESTAURANTS,
      payload: { restaurants },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} category
 */
export function updateSelectedCategory (category) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_CATEGORY,
      payload: { category },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} input
 */
export function updateSearchInput (input) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_INPUT,
      payload: { input },
    })
  }
}

/**
 * @returns {ThunkFunction}
 */
export function updateSearchFilter (filter) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SEARCH_FILTER,
      payload: { filter },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} filter
 * @param {string} option
 */
export function updateFilterOption (filter, option) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER_OPTION,
      payload: { filter, option },
    })
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} option
 */
export function updateFilterMultipleOptions (filter, option, forceSelect) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER_MULTIPLE_OPTIONS,
      payload: { filter, option, forceSelect },
    })
  }
}

export function resetSelectedFilters () {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.RESET_SELECTED_FILTERS,
      paylaod: {},
    })
  }
}

export function updateFilters () {
  return async (dispatch) => {
    const data = await constants.filters
    _.forEach(data, (filter, key) => dispatch(updateFilterData(key, filter)))
  }
}

/**
 * @returns {ThunkFunction}
 * @param {string} filter
 * @param {any} data
 */
export function updateFilterData (filter, data) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_FILTER_DATA,
      payload: { filter, data },
    })
  }
}
