import Content from '@/components/Content'
import FullpageDialog from '@/components/FullpageDialog'
import NavBar from '@/components/NavBar'
import NavButton from '@/components/NavBar/NavButton'
import NavIconButton from '@/components/NavBar/NavIconButton'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Category from './Category'

/**
 * @component
 * @param {*} props
 */
export default function FindMoreDialogPage (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.pages.findMore)
  const togglePage = () => {
    dispatch(actions.app.togglePage('findMore'))
    dispatch(actions.app.updateSelectedCategory())
  }
  const goToPage = (page) => {
    dispatch(actions.app.resetPages())
    dispatch(actions.app.togglePage(page))
  }

  return (
    <FullpageDialog open={open} onClose={togglePage}>
      <div className={classes.container}>
        <NavBar
          logo
          startButtons={[<NavButton key='FindMoreDialogPage-start-0' variant='back' onClick={togglePage} />]}
          endButtons={[
            <NavIconButton key='FindMoreDialogPage-end-0' variant='search' onClick={() => goToPage('search')} />,
            <NavIconButton key='FindMoreDialogPage-end-1' variant='filter' onClick={() => goToPage('filter')} />,
          ]}
        />
        <Content>
          <Category />
        </Content>
      </div>
    </FullpageDialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))
