import { createMuiTheme } from '@material-ui/core/styles'
import colors from './colors'

const fontFamily = 'Noto Sans HK, sans-serif, Roboto, Helvetica, Arial, sans-serif'

const theme = {
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: { main: colors.secondary },
    text: {
      primary: '#717171',
      secondary: colors.primary,
    },
  },
  overrides: {
    MuiTypography: {
      root: { fontFamily },
      h1: { fontFamily },
      h2: { fontFamily },
      h3: { fontFamily },
      h4: { fontFamily },
      h5: { fontFamily },
      h6: { fontFamily },
      subtitle1: { fontFamily },
      subtitle2: { fontFamily },
      body1: { fontFamily },
      body2: { fontFamily },
      button: { fontFamily },
      caption: { fontFamily },
      overline: { fontFamily },
    },
  },
}
console.log(theme)
export default createMuiTheme(theme)
