import Landing from '@/pages/Landing'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

export default function AppRouter (props) {
  return (
    <Switch>
      <Route exact path='/' component={Landing} />
      {/* // Redirect all 404's to home */}
      <Redirect to='/' />
    </Switch>
  )
}
