import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Steamfresh.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Steamfresh (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          韓式海鮮蒸氣火鍋專門店「九重鮮」所有食材都經過精挑細選，餐廳出品的海鮮和韓式菜多年來都是品質保証及高評價，今年特別挑選日本蟹皇松葉蟹海鮮盆菜...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        韓式海鮮蒸氣火鍋專門店「九重鮮」所有食材都經過精挑細選，餐廳出品的海鮮和韓式菜多年來都是品質保証及高評價，今年特別挑選日本蟹皇松葉蟹海鮮盆菜(四至六位用)，追求方便又美味的靈感，合併起來，有齊色，香，味，快，靚，正，鮮！盆菜套餐包含了已熟日本蟹皇松葉蟹一隻(約1.5-2斤)，海鮮凍盆包(海蝦8隻、日本帶子4件、油甲16隻)，盆滿鉢滿盆菜(西班牙杜洛克豬手，本灣海蝦8隻，鮑汁鮮鮑魚6隻，發財扣花膠，花尾龍躉球等)，以及臘味炒糯米飯(一份)。自家宴客菜，方便又新鮮。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
