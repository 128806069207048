import produce from 'immer'
import _ from 'lodash'
import ActionTypes from './ActionTypes'

const initialDialogPages = {
  search: false,
  filter: false,
  filterResult: false,
  findMore: false,
}

const initialDialogs = {
  privacyPolicy: false,
  termsAndConditions: false,
  cny2021RestaurantDetail: false,
  share: false,
  bookmark: false,
}

const initialFilters = {
  options: {
    data: null,
    selected: [],
  },
  price: {
    data: null,
    selected: [],
  },
  style: {
    data: null,
    selected: [],
  },
  district: {
    data: null,
    selected: [],
  },
  discount: {
    data: null,
    selected: [],
  },
}

export const initialState = {
  isInit: false,
  isLoading: false,
  dialogs: initialDialogs,
  pages: initialDialogPages,
  selectedCategory: null,
  searchInput: '',
  searchFilter: null,
  filters: initialFilters,
  restaurants: null,
}

export default produce(
  (draft, action) => {
    switch (action?.type) {
      case ActionTypes.INIT: {
        draft.isInit = true
        break
      }
      case ActionTypes.UPDATE_LOADING: {
        const { isLoading } = action.payload
        draft.isLoading = isLoading
        break
      }
      case ActionTypes.TOGGLE_PAGE: {
        const { page, open } = action.payload
        if (open !== undefined) {
          draft.pages[page] = open
        } else {
          draft.pages[page] = !draft.pages[page]
        }
        break
      }
      case ActionTypes.RESET_PAGES: {
        draft.pages = initialDialogPages
        break
      }
      case ActionTypes.TOGGLE_DIALOG: {
        const { dialog, open } = action.payload
        if (open !== undefined) {
          draft.dialogs[dialog] = open
        } else {
          draft.dialogs[dialog] = !draft.dialogs[dialog]
        }
        break
      }
      case ActionTypes.RESET_DIALOGS: {
        draft.dialogs = initialDialogs
        break
      }
      case ActionTypes.FETCH_RESTAURANTS: {
        const { restaurants } = action.payload
        draft.restaurants = restaurants
        break
      }
      case ActionTypes.UPDATE_SELECTED_CATEGORY: {
        const { category } = action.payload
        draft.selectedCategory = category
        break
      }
      case ActionTypes.UPDATE_SEARCH_INPUT: {
        const { input } = action.payload
        draft.searchInput = input
        break
      }
      case ActionTypes.UPDATE_SEARCH_FILTER: {
        const { filter } = action.payload
        if (filter !== undefined) {
          draft.searchFilter = filter
        } else {
          const filters = _.clone(draft.filters)
          const filter = {}
          _.forEach(filters, (f, key) => { filter[key] = f.selected })
          draft.searchFilter = filter
        }
        break
      }
      case ActionTypes.UPDATE_FILTER_OPTION: {
        const { filter, option } = action.payload
        if (!_.includes(draft.filters[filter].selected, option)) {
          draft.filters[filter].selected = [option]
        } else {
          // already selected
          draft.filters[filter].selected = []
        }
        break
      }
      case ActionTypes.UPDATE_FILTER_MULTIPLE_OPTIONS: {
        const {
          filter,
          option,
          forceSelect, // 控制是否 select option, 不給的話是 toggle
        } = action.payload
        const options = draft.filters[filter].selected
        const select = () => {
          draft.filters[filter].selected = [...options, option]
        }
        const deselect = () => {
          const newOptions = [...draft.filters[filter].selected]
          _.remove(newOptions, o => o === option)
          draft.filters[filter].selected = newOptions
        }

        switch (forceSelect) {
          case true:
            if (!_.includes(options, option)) {
              select()
            }
            break
          case false:
            deselect()
            break
          default:
            if (!_.includes(options, option)) {
              select()
            } else {
              deselect()
            }
            break
        }
        break
      }
      case ActionTypes.RESET_SELECTED_FILTERS: {
        const filters = _.clone(draft.filters)
        _.forEach(filters, (f, key) => { f.selected = initialFilters[key].selected })
        draft.filters = filters
        break
      }
      case ActionTypes.UPDATE_FILTER_DATA: {
        const { filter, data } = action.payload
        draft.filters[filter].data = data
        break
      }
      default:
        break
    }
  },
  initialState,
)
