/* eslint-disable no-lone-blocks */
import Logo from '@/assets/images/logo.png'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

NavTitle.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.bool,
}

/**
 * @component
 * @param {{
 * title?: string
 * logo?: boolean
 * }} props
 */
export default function NavTitle (props) {
  const { title, logo } = props
  const classes = useStyles(props)

  if (logo) {
    return <img className={classes.logo} src={Logo} alt='logo' />
  }

  return (
    <Typography variant='h5' className={classes.title} align='center'>{title}</Typography>
  )
}

const useStyles = makeStyles(theme => ({
  logo: {
    height: 54,
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.8rem',
    fontWeight: 500,
  },
}))
