import RestaurantCard from '@/components/RestaurantCard'
import { toUpperCase } from '@/libs/string'
import { useSelector } from '@/redux'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function SearchResult (props) {
  const classes = useStyles(props)
  const restaurants = useSelector(state => state.app.restaurants)
  const searchInput = useSelector(state => state.app.searchInput)
  const results = _.filter(restaurants, r => searchInput && _.includes(toUpperCase(r.name), toUpperCase(searchInput)))
  const randomized = _.shuffle(results)

  return (
    <div className={classes.result}>
      <Grid container>
        {_.map(randomized, (result, index) => {
          return (
            <Grid item key={index} className={classes.gridItem} xs={6} sm={4} md={3} lg={2}>
              <RestaurantCard data={result} size='medium' />
            </Grid>
          )
        })}
      </Grid>
      <div className={classes.resultHint}>
        {searchInput && <Typography className={classes.hint}>{_.isEmpty(results) ? '找不到相關餐廳' : '只有以上搜尋結果'}</Typography>}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  result: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resultHint: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 80,
  },
  hint: {
    fontSize: '1.5rem',
    fontWeight: 300,
    color: '#CBCBCB',
  },
}))
