import Content from '@/components/Content'
import FullpageDialog from '@/components/FullpageDialog'
import NavBar from '@/components/NavBar'
import NavButton from '@/components/NavBar/NavButton'
import { actions, useDispatch, useSelector } from '@/redux'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Chips from './Chips'
import CollapsibleList from './CollapsibleList'
import RegionList from './RegionList'

/**
 * @component
 * @param {*} props
 */
export default function FilterDialogPage (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.pages.filter)
  const togglePage = () => dispatch(actions.app.togglePage('filter'))
  const filters = useSelector(state => state.app.filters)
  const handleSubmitFilter = () => {
    dispatch(actions.app.updateSearchFilter())
    dispatch(actions.app.togglePage('filterResult', true))
  }

  return (
    <FullpageDialog open={open} onClose={togglePage}>
      <div className={classes.container}>
        <NavBar
          title='篩選'
          startButtons={[<NavButton key='FilterDialogPage-start-0' variant='cancel' onClick={togglePage} />]}
        />
        <Content>
          <Chips list={filters.options.data} filter='options' />
          <CollapsibleList title='價錢' list={filters.price.data} filter='price' />
          <CollapsibleList title='菜式' list={filters.style.data} filter='style' />
          <RegionList title='地區' list={filters.district.data} filter='district' />
          <CollapsibleList title='折扣' list={filters.discount.data} filter='discount' />
          <Button
            color='primary'
            variant='contained'
            fullWidth
            className={classes.submitButton}
            classes={{ contained: classes.shadow }}
            onClick={handleSubmitFilter}
          >完成
          </Button>
        </Content>
      </div>
    </FullpageDialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    flexShrink: 0,
    fontSize: '1.8rem',
    borderRadius: 800,
    padding: 16,
    marginTop: 32,
    marginBottom: 32,
  },
  shadow: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
  },
}))
