import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

Chip.propTypes = {
  option: PropTypes.string,
  filter: PropTypes.string,
}

/**
 * @component
 * @param {{
 * option: string
 * filter: string
 * }} props
 */
export default function Chip (props) {
  const { option, filter } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const selectedOptions = useSelector(state => state.app.filters[filter].selected)
  const isSelected = _.includes(selectedOptions, option)
  const handleSelect = () => {
    dispatch(actions.app.updateFilterMultipleOptions(filter, option))
  }

  return (
    <Typography className={clsx(classes.chip, isSelected && classes.selected)} onClick={handleSelect}>
      {option}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    padding: '12px 24px',
    margin: 8,
    fontSize: '1.8rem',
    color: colors.gray1,
    borderRadius: 800,
    border: `1px solid ${colors.gray2}`,
  },
  selected: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
}))
