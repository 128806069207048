import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Daigongkun.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Daigongkun (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          「大公館」位於西九龍全新創意地標D2 Place一期，「大公館」一直致力為各品味之士提供一個既舒適又不失優雅的休閒之所；以其豐富多樣之美食口碑載道...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        「大公館」位於西九龍全新創意地標D2 Place一期，「大公館」一直致力為各品味之士提供一個既舒適又不失優雅的休閒之所；以其豐富多樣之美食口碑載道，深受各方食客擁戴。供應正宗粵菜的《萬慶軒》，行政總廚陳偉庭來自米芝蓮三星級食府且曾榮獲多項美食大賽獎項，陳師傅致力以地道的廣東烹調方式炮製時令食材，務求滿足美食愛好者們的味蕾。
      </p>
      <p>
        【大公館極品盆菜】<strong>盆菜一天前預訂</strong><br />
        精心挑選多款珍貴食材入饌，包括原隻吉品鮑魚、游水龍蝦、高湯燴花膠、紅燒海參及大公館招牌稻草肉等應有盡有。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
