import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Lokcy.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Lokcy (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          「樂翠園海鮮菜館」，顧名思義主要以海鮮為主，今年推出盆菜系列，主要以鮑魚、柱甫、燒鵝、燒肉等食材組成，想嘗試傳統盆菜，就一定要試下樂翠園推出...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        「樂翠園海鮮菜館」，顧名思義主要以海鮮為主，今年推出盆菜系列，主要以鮑魚、柱甫、燒鵝、燒肉等食材組成，想嘗試傳統盆菜，就一定要試下樂翠園推出的盆菜系列！
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
