import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Prince.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Prince (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          王子飯店來自國內的著名飲食集團,以鮑魚,花膠料理馳名,2009及2010連續兩年被權威飲食指南米芝蓮評選為十分舒適的時尚中菜餐廳。餐廳於今年隆重推出了...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        王子飯店來自國內的著名飲食集團,以鮑魚,花膠料理馳名,2009及2010連續兩年被權威飲食指南米芝蓮評選為十分舒適的時尚中菜餐廳。餐廳於今年隆重推出了「迎春接福團圓盆菜」及「迎金牛行運升級版盆菜」兩款盆菜。食材採用了六頭鮑魚，上等靚花膠，炆鵝手，優質海參等十多款食材，分別有四人及六人份量。當食盆菜時人們圍坐一起，象徵合家團圓，滿堂吉慶，意義非凡。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
