import { Dialog, Fade, Grow, Slide } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

FullpageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  transition: PropTypes.oneOf(['slide', 'fade', 'grow']),
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  children: PropTypes.any,
  classes: PropTypes.object,
}

const TransitionFade = forwardRef(function Transition (props, ref) {
  const { transition, direction } = props
  switch (transition) {
    case 'slide':
      return <Slide direction={direction} ref={ref} {...props} />
    case 'grow':
      return <Grow ref={ref} {...props} />
    case 'fade':
      return <Fade ref={ref} {...props} />
    default:
      break
  }
})

/**
 * @component
 * @param {{
 * open: boolean
 * onClose: Funcion
 * transition?: 'slide' | 'fade' | 'grow'
 * direction?: 'left' | 'right' | 'up' | 'down'
 * children?: any
 * classes?: object
 * }} props
 */
export default function FullpageDialog (props) {
  const { open, onClose, transition = 'slide', direction = 'left', children, classes } = props

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      classes={classes}
      TransitionComponent={TransitionFade}
      TransitionProps={{ transition, direction }}
    >
      {children}
    </Dialog>
  )
}
