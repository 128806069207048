import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

FindMoreButton.propTypes = {
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * onClick: Function
 * }} props
 */
export default function FindMoreButton (props) {
  const classes = useStyles(props)
  const { onClick } = props

  return (
    <Typography variant='h5' className={classes.text} onClick={onClick}>查看全部</Typography>
  )
}

const useStyles = makeStyles(theme => ({
  text: {
    color: colors.blue1,
    fontSize: '1.6rem',
    cursor: 'pointer',
  },
}))
