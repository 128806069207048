import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Gwaishifong.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Gwaishifong (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          「瑰•私房」是一間專注於私房菜的餐廳，今年餐廳為大家呈獻3款不同份量的盆菜，分別為四人份量 ($988)，六人份量 ($1388)以及十人份量 ($1988)。餐廳...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        「瑰•私房」是一間專注於私房菜的餐廳，今年餐廳為大家呈獻3款不同份量的盆菜，分別為四人份量 ($988)，六人份量 ($1388)以及十人份量 ($1988)。餐廳選用上等香扣鮑魚、靚蠔皇花膠、原粒柱甫、濃炆玉掌、家鄉腩仔等優質材料。同時餐廳老闆聘用弱勢社群、青年員工回饋社會，給予機會他們發揮其優點，以及弱勢社群、青年員工在制作盤菜時，更專注和細心，令整個盆菜在製作過程中變得更加完美。
      </p>
      <p>
        瑰•私房在賣出200個盤菜後會贊助親子王國 Baby-Kingdom 200底年糕/蘿蔔糕送贈予老人家們，在為節慶歡聚的同時又可以行善舉，實為一舉兩得。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
