export default {
  INIT: 'APP/INIT',
  UPDATE_LOADING: 'APP/UPDATE_LOADING',
  TOGGLE_PAGE: 'APP/TOGGLE_PAGE',
  RESET_PAGES: 'APP/RESET_PAGES',
  TOGGLE_DIALOG: 'APP/TOGGLE_DIALOG',
  RESET_DIALOGS: 'APP/RESET_DIALOGS',
  FETCH_RESTAURANTS: 'APP/FETCH_RESTAURANTS',
  UPDATE_SELECTED_CATEGORY: 'APP/UPDATE_SELECTED_CATEGORY',
  UPDATE_SEARCH_INPUT: 'APP/UPDATE_SEARCH_INPUT',
  UPDATE_SEARCH_FILTER: 'APP/UPDATE_SEARCH_FILTER',
  UPDATE_FILTER_OPTION: 'APP/UPDATE_FILTER_OPTION',
  UPDATE_FILTER_MULTIPLE_OPTIONS: 'APP/UPDATE_FILTER_MULTIPLE_OPTIONS',
  RESET_SELECTED_FILTERS: 'APP/RESET_SELECTED_FILTERS',
  UPDATE_FILTER_DATA: 'APP/UPDATE_FILTER_DATA',
  UPDATE_FILTER: 'APP/UPDATE_FILTER',
}
