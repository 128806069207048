import ArrowDownIcon from '@/assets/icons/custom/ArrowDown'
import { useSelector } from '@/redux'
import colors from '@/theme/colors'
import { Collapse, Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SubList from './SubList'

RegionList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  filter: PropTypes.string,
}

/**
 * @component
 * @param {{
 * title: string
 * list: IRegions
 * filter: string
 * }} props
 */
export default function RegionList (props) {
  const { title, list, filter } = props
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const selectedOptions = useSelector(state => state.app.filters[filter].selected)
  const isSelected = !_.isEmpty(selectedOptions)

  return (
    <List className={classes.root} classes={{ root: classes.noPadding }}>
      <ListItem button disableRipple onClick={toggleOpen} className={classes.summary}>
        <ListItemText primary={title} primaryTypographyProps={{ className: clsx(classes.listTitle, open && classes.listTitleOpen, !open && isSelected && classes.listTitleSetted) }} />
        <ArrowDownIcon strokecolor={colors.gray1} className={clsx(classes.expandIcon, open && classes.collapseIcon)} />
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' classes={{ padding: classes.detailPadding }}>
          {_.map(list, (item, key) => {
            return (<SubList key={key} title={key} list={item} filter={filter} />)
          })}
        </List>
      </Collapse>
      <Divider variant='fullWidth' />
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: -8,
    marginRight: -8,
  },
  noPadding: {
    padding: 0,
  },
  summary: {
    height: 60,
  },
  detailPadding: {
    paddingTop: 0,
    paddingBottom: 16,
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
  listTitle: {
    fontSize: '1.8rem',
  },
  listTitleOpen: {
    fontWeight: 700,
  },
  listTitleSetted: {
    color: colors.brown,
  },
  listItemText: {
    fontSize: '1.6rem',
  },
  listItemTextSelected: {
    color: colors.primary,
  },
  listItemIcon: {
    minWidth: 0,
  },
  expandIcon: {
    transition: 'all 300ms ease',
  },
  collapseIcon: {
    transform: 'rotate(-180deg)',
  },
}))
