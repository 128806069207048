export default {
  white: '#FFFFFF',
  white1: '#F8F8F8',
  black: '#000000',
  black1: '#434343',
  black2: '#333333',
  primary: '#EAAE18',
  secondary: '#E8E8E8',
  secondaryOpacity70: '#E8E8E8B3',
  gray1: '#717171',
  gray2: '#D2D2D2',
  gray3: '#AEAEAE',
  gray4: '#7D7D7D',
  blue1: '#007DA3',
  brown: '#9E791D',
}
