import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function ArrowBack (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 9 16' htmlColor='#ffffff00'>
      <path d='M8 15L1 8L8 1' stroke={strokecolor} strokeWidth='1.3' strokeLinecap='square' strokeLinejoin='round' />
    </SvgIcon>
  )
}
