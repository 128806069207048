import CrossIcon from '@/assets/icons/custom/Cross'
import { actions, useDispatch, useSelector } from '@/redux'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import markdownFile from './privacyPolicy.md'

/**
 * @component
 * @param {*} props
 */
export default function PrivacyPolicy (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const open = useSelector(state => state.app.dialogs.privacyPolicy)
  const handleClose = () => dispatch(actions.app.toggleDialog('privacyPolicy', false))
  const [md, setMd] = useState('')

  useEffect(async () => {
    const response = await fetch(markdownFile)
    const text = await response.text()
    setMd(text)
  }, [])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton className={classes.closeButton} aria-label='close' onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ReactMarkdown source={md} />
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    lineHeight: 1.5,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    border: '1px solid',
    padding: 8,
  },
}))
