import Cny2021RestaurantDetailDialog from '@/campaigns/cny2021/pages/Cny2021/DetailDialog'
import BackdropLoading from '@/components/BackdropLoading'
import AppRouter from '@/pages/AppRouter'
import { actions, useDispatch, useSelector } from '@/redux'
import React, { useEffect } from 'react'

export default function Main (props) {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.app.isLoading)

  useEffect(() => {
    dispatch(actions.app.init())
  }, [])

  return (
    <>
      <AppRouter />
      <BackdropLoading open={isLoading} />
      <Cny2021RestaurantDetailDialog />
    </>
  )
}
