import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function ArrowDown (props) {
  const { strokecolor } = props
  return (
    <SvgIcon {...props} viewBox='0 0 14 8' htmlColor='#ffffff00'>
      <path d='M1 1L7 7L13 1' stroke={strokecolor} strokeWidth='1.3' strokeLinecap='square' strokeLinejoin='round' />
    </SvgIcon>
  )
}
