import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

ReadMoreButton.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * }} props
 */
export default function ReadMoreButton (props) {
  const { readMore, onClick } = props
  const classes = useStyles(props)

  return (
    <Typography
      component='span'
      className={classes.readMore}
      style={{ marginLeft: readMore && 8 }}
      onClick={onClick}
    >
      {readMore ? '顯示較少' : '更多'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  readMore: {
    color: '#ef820b',
    fontSize: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
    wordBreak: 'keep-all',
  },
}))
