export default {
  zIndex: {
    BACKDROP_LOADING: 100,
    NAVBAR: 10,
  },
  height: {
    NAVBAR: 64,
    'FOOTER/MIN_HEIGHT': 168,
  },
  width: {
    'CONTAINER/MAX_WIDTH': 1400,
  },
  CARD_ASPECT_RATIO: 0.8,
}
