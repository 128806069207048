import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Choikee.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Choikee (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          又到一年一度的農曆新年，財記美食「新春豐足至尊盤菜」反應十分熱烈！豐衣足食為您精挑細選了十多款優質食材，包括高湯燴花膠，日本原粒瑤柱，椒鹽海...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        又到一年一度的農曆新年，財記美食「新春豐足至尊盤菜」反應十分熱烈！豐衣足食為您精挑細選了十多款優質食材，包括高湯燴花膠，日本原粒瑤柱，椒鹽海虎蝦，發財厚花菇等食材，仲可以以優惠價加配阿拉斯加長腳蟹一隻($1300)(約三斤半)，最啱一家大細親戚朋友一齊食，享受家庭溫暖！
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
