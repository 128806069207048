import RestaurantCard from '@/components/RestaurantCard'
import constants from '@/constants'
import { actions, useDispatch, useSelector } from '@/redux'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import FindMore from './FindMore'
import Title from './Title'

Category.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
}

const skeletons = [...Array(12).keys()]
const skeletonSize = (size) => {
  let height
  let width
  switch (size) {
    case 'small':
      height = 160
      width = 160 * constants.app.CARD_ASPECT_RATIO
      break
    case 'medium':
      height = 200
      width = 200 * constants.app.CARD_ASPECT_RATIO
      break
    case 'large':
      height = 320
      width = 320 * constants.app.CARD_ASPECT_RATIO
      break
    default:
      break
  }
  return { width, height }
}

/**
 * @component
 * @param {{
 * size: 'small' | 'medium' | 'large'
 * title: string
 * list: IRestaurant[]
 * }} props
 */
export default function Category (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { size, title, list, link } = props
  const isInit = useSelector(state => state.app.isInit)
  const randomized = _.shuffle(list)
  const history = useHistory()

  return (
    <div className={classes.container}>
      <section className={classes.header}>
        <Title size={size} title={title} />
        <FindMore
          onClick={() => {
            if (link) {
              history.push(link)
              return
            }
            dispatch(actions.app.updateSelectedCategory(title))
            dispatch(actions.app.togglePage('findMore'))
          }}
        />
      </section>

      <section className={classes.cards}>
        {
          isInit
            ? _.map(randomized, (r, index) => <RestaurantCard key={index} data={r} size={size} />)
            : _.map(skeletons, (s, index) => <Skeleton className={classes.skeleton} key={index} variant='rect' width={skeletonSize(size).width} height={skeletonSize(size).height} />)
        }
      </section>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    margin: '16px -8px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
  },
  cards: {
    display: 'flex',
    paddingLeft: 8,
    paddingRight: 8,
    overflowX: 'auto',
    '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
    'scrollbar-width': 'none', /* Firefox */
  },
  'cards::-webkit-scrollbar': {
    display: 'none', /* Safari and Chrome */
  },
  skeleton: {
    margin: 8,
    flexShrink: 0,
    borderRadius: 32,
  },
}))
