import { transformation } from '@/libs/cloudinary'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

/**
 * @param {IBannerItem} props
 */
export default function BannerItem (props) {
  const { item } = props
  const classes = useStyles({ ...props, item })
  const [mouseDownLocation, setMouseDownLocation] = useState({ x: 0, y: 0 })

  return (
    <Paper
      className={classes.bannerItemContainer}
      onMouseDown={e => {
        setMouseDownLocation({ x: e.pageX, y: e.pageY })
      }}
      onMouseUp={e => {
        if ((mouseDownLocation.x - e.pageX) + (mouseDownLocation.y - e.pageY) <= 30) {
          item.onClick()
        }
      }}
      onClick={() => {
      }}
    >
      <figure className={classes.bannerImage} />
    </Paper>
  )
}
const useStyles = makeStyles(theme => ({
  bannerItemContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxHeight: props => props.item.image.height,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  bannerImage: {
    maxWidth: 1024, // 最寬就是 1024px 畫面更寬就置中
    width: '100%',
    paddingTop: props => {
      const ratio = props.item.image.height / props.item.image.width
      return `${ratio * 100}%`
    },
    backgroundImage: props => {
      const url = transformation(props.item.image.url, 'w_1024')
      return `url(${url})`
    },
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: 0,
  },
}))
