import Main from '@/pages/Main'
import store from '@/redux/store'
import theme from '@/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

console.log('theme', theme)

export default function App () {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}
