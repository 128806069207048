import RestaurantCard from '@/components/RestaurantCard'
import { useSelector } from '@/redux'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function Category (props) {
  const classes = useStyles(props)
  const title = useSelector(state => state.app.selectedCategory)
  const restaurants = useSelector(state => state.app.restaurants)
  const randomized = _.shuffle(restaurants)

  return (
    <div className={classes.container}>
      <Typography color='textPrimary' className={classes.title}>{title}</Typography>
      <Grid container>
        {_.map(_.filter(randomized, r => _.includes(r.tags, title)), (r, index) => {
          return (
            <Grid item key={index} className={classes.gridItem} xs={6} sm={4} md={3} lg={2}>
              <RestaurantCard data={r} size='medium' />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 0px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 21,
    fontWeight: 500,
    padding: '8px 16px',
  },
}))
