import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Happytogether.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Happytogether (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          農曆新年即將來到，當然小不得同撈同煲的豪華盆菜，今日開始接受預訂，預早訂購就送懷舊糖不甩+生菜（數量有限，送完即止），今年更有升級版，適合不同...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        農曆新年即將來到，當然小不得同撈同煲的豪華盆菜，今日開始接受預訂，預早訂購就送懷舊糖不甩+生菜（數量有限，送完即止），今年更有升級版，適合不同食量人士，大家快來選購，於這個新年一起同撈同煲，撈到風生水起！
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
