import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function Bookmark (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 23 23'>
      <path fillRule='evenodd' clipRule='evenodd' d='M16 2v17.582l-4-3.512-4 3.512v-17.582h8zm2-2h-12v24l6-5.269 6 5.269v-24z' />
    </SvgIcon>
  )
}
