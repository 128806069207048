import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function Logo (props) {
  const classes = useStyles(props)
  const year = new Date().getFullYear()

  return (
    <Typography variant='h5' className={classes.logo}>© {year} 點單 DimOrder </Typography>
  )
}
const useStyles = makeStyles(theme => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
}))
