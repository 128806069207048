import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Peony.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Peony (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          「遊園」發跡於西九劇曲中心，為近年新派興起的中菜，然而於今年返璞歸真呈獻「遊園藏珍‧海味一品煲」，匯聚多款珍餚海味，包括原隻蠔皇飽魚、滋補花...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        「遊園」發跡於西九劇曲中心，為近年新派興起的中菜，然而於今年返璞歸真呈獻「遊園藏珍‧海味一品煲」，匯聚多款珍餚海味，包括原隻蠔皇飽魚、滋補花膠、印尼刺参及宗谷瑤柱等；一品煲(四位用)定價為港幣688。一同在寒冷的冬季感受美食為你帶來的暖意及迎接新一年的到來。每天供應數量有限，售完即止。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
