const options = [
  // '跨區外送',
  '自取',
  '米芝蓮',
  '網店',
  '推薦',
]

const price = [
  '200以下',
  '200－300',
  '301－400',
  '400以上',
]

const style = [
  '港式',
  '中菜',
  '西式',
  '意式',
  '台灣菜',
  '日本菜',
  '韓國菜',
  '泰國菜',
  '多國菜',
  '其他',
]

const district = {
  香港島: [
    '中西區',
    '灣仔區',
    '東區',
    '南區',
  ],
  九龍: [
    '深水埗區',
    '油尖旺區',
    '九龍城區',
    '黃大仙區',
    '觀塘區',
  ],
  新界: [
    '葵青區',
    '荃灣區',
    '屯門區',
    '元朗區',
    '北區',
    '大埔區',
    '沙田區',
    '西貢區',
  ],
  離島: [
    '離島區',
  ],
}

const discount = [
  '九折',
  '八折',
  '七折或以下',
  '項目減免',
]

export default {
  options,
  price,
  style,
  district,
  discount,
}
