import { actions, useDispatch, useSelector } from '@/redux'
import { Card, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CrossIcon from '@material-ui/icons/Close'
import React from 'react'

export default function Bookmark (props, { setA2HPState, isAppInstallable }) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.dialogs.bookmark)
  console.log({ open })
  const handleClose = () => dispatch(actions.app.toggleDialog('bookmark', false))
  const userAgent = window.navigator.userAgent
  window.addEventListener('beforeinstallprompt', (event) => {
    window.deferredPrompt = event
  })
  window.addEventListener('appinstalled', (event) => {
    console.log('👍', 'appinstalled', event)
  })
  const handleA2hs = () => {
    const promptEvent = window.deferredPrompt
    if (!promptEvent) {
      console.log('stopped')
      return
    }
    promptEvent.prompt()
    promptEvent.userChoice.then((result) => {
      window.deferredPrompt = null
    })
  }
  if ((/iphone|ipod|ipad/i).test(userAgent)) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        classes={{ paper: classes.paperRoot, paperFullWidth: classes.paperFullWidth }}
      >
        <Card className={classes.card}>
          <IconButton className={classes.closeButton} aria-label='close' onClick={handleClose}>
            <CrossIcon />
          </IconButton>
          <DialogContent className={classes.msgtext}>
            把點單網頁加到主頁，請使用safari瀏覽器
          </DialogContent>
          <DialogContent className={classes.msgtext}>
            按下 <img src='https://img.icons8.com/fluent-systems-filled/24/000000/upload.png' /> 按鈕，然後按 '加至主頁面'
          </DialogContent>
        </Card>
      </Dialog>
    )
  } else if (/android/i.test(userAgent)) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        classes={{ paper: classes.paperRoot, paperFullWidth: classes.paperFullWidth }}
      >
        <DialogContent className={classes.andtext}>
          下載點單到主畫面
        </DialogContent>
        <button className={classes.dlbtn} onClick={handleA2hs}>下載 </button>
      </Dialog>
    )
  } else return (null)
}

const useStyles = makeStyles(theme => ({
  paperFullWidth: {
    width: '100%',
    minWidth: '100%',
  },
  paperRoot: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 0,
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: '16px 24px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
  },
  iostext: {
    fontSize: '14px',
  },
  andtext: {
    position: 'relative',
    top: -7,
    fontSize: '22px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  dlbtn: {
    position: 'absolute',
    fontWeight: 'bold',
    blockSize: '50px',
    width: '80px',
    border: 'none',
    borderRadius: '15px',
    right: '10px',
    top: '25px',
    padding: '15',
    margin: '20',
    color: 'white',
    fontSize: '2.1rem',
    backgroundColor: 'orange',
  },
}))
