import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Chip from './Chip'

Chips.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
}

/**
 * @component
 * @param {{
 * list: string[]
 * filter: string
 * }} props
 */
export default function Chips (props) {
  const classes = useStyles(props)
  const { list, filter } = props

  return (
    <div className={classes.chips}>
      {_.map(list, (option, index) => <Chip key={index} option={option} filter={filter} />)}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  chips: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 32,
    marginBottom: 32,
  },
}))
