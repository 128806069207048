import LanternIcon from '@/campaigns/cny2021/assets/icons/lantern.svg'
import { numberWithCommas } from '@/libs/string'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Section from './Section'
import Title from './Title'

Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
}

/**
 * @typedef IOption
 * @property {string} name
 * @property {number} price
 */

/**
 * @component
 * @param {{
 * options: IOption[]
 * }} props
 */
export default function Options (props) {
  const { options } = props
  const classes = useStyles(props)

  if (_.isEmpty(options)) return null

  return (
    <Section>
      <Title title='款式價錢' icon={<img src={LanternIcon} />} />
      <Grid container>
        {_.map(options, (option, index) => {
          return (
            <Grid item xs={option.xs ?? 4} key={index}>
              <div className={classes.optionContainer}>
                <Typography className={classes.text}>{option.name}</Typography>
                <Typography className={classes.price}>
                  <Typography className={clsx(classes.text, classes.symbol)} component='span'>$</Typography>
                  {numberWithCommas(option.price)}
                </Typography>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}

const useStyles = makeStyles(theme => ({
  optionContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
  },
  text: {
    fontSize: '1.5rem',
    color: '#333333',
  },
  price: {
    fontSize: '2rem',
    fontWeight: 500,
    color: '#c90005',
  },
  symbol: {
    marginRight: 2,
  },
}))
