import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Epot.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Epot (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          每到農歷新年都只有盤菜覺得太單調？一鍋堂除了有主打的煮意團圓盆菜，更有雙冬羊腩煲，為大家帶來更多溫暖!各款都設有不同份量，為各大大小小不同家庭...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        每到農歷新年都只有盤菜覺得太單調？一鍋堂除了有主打的煮意團圓盆菜，更有雙冬羊腩煲，為大家帶來更多溫暖!各款都設有不同份量，為各大大小小不同家庭量身打做。用上各種各貴食材，如富貴金湯鮑，美人扒花膠，南乳燜豬手。保證大家一試難忘。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
