import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

Title.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

/**
 * @component
 * @param {{
 * icon: Node
 * title: string
 * subtitle?: string
 * }} props
 */
export default function Title (props) {
  const { icon, title, subtitle } = props
  const classes = useStyles(props)

  return (
    <div className={classes.titleWrap}>
      <div className={classes.titleContainer}>
        <div className={classes.iconContainer}>
          {icon}
        </div>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      {subtitle && <Typography component='p' className={classes.subtitle}>{subtitle}</Typography>}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  titleWrap: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > :last-child': {
      marginBottom: 4,
    },
  },
  titleContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: 4,
  },
  title: {
    fontSize: '1.5rem',
  },
  subtitle: {
    flexShrink: 0,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#383838',
    marginTop: 8,
  },
}))
