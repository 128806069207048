
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import Slider from 'react-slick'
import BannerItem from './BannerItem'

/**
 *
 * @param {{
 * banners: IBannerItem[]
 * }} props
 */
export default function Banner (props) {
  const { banners } = props
  const classes = useStyles(props)
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  return (
    <Slider {...settings} className={clsx(classes.slider)}>
      {_.map(banners, (banner, index) => {
        return <BannerItem key={index} item={banner} />
      })}
    </Slider>
  )
}

const useStyles = makeStyles(theme => ({
  slider: {
    marginRight: -8,
    marginLeft: -8,
  },
}))
