import constants from '@/constants'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

/**
 * @component
 * @param {{
 * children?: any
 * className?: string
 * maxWidth?: string | number
 * }} props
 */
export default function Content (props) {
  const { children, className, maxWidth } = props
  const classes = useStyles({ ...props, maxWidth })

  return (
    <Container
      className={clsx(classes.content, className)}
      classes={{ maxWidthLg: classes.maxWidthLg }}
    >{children}
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: constants.app.height.NAVBAR,
    padding: 8,
  },
  maxWidthLg: {
    maxWidth: props => props.maxWidth ?? constants.app.width['CONTAINER/MAX_WIDTH'],
  },
}))
