import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Chiulokyuen.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Chiulokyuen (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          潮樂園特別推出以上乘食材炮製，寓意團團圓圓，盆滿缽滿的鮑參海味潮式盆菜，讓您與家人喜迎新歲，締造滋味滿溢的節慶。 嚴選食材包括：豉油皇七頭大花竹蝦...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        潮樂園特別推出以上乘食材炮製，寓意團團圓圓，盆滿缽滿的鮑參海味潮式盆菜，讓您與家人喜迎新歲，締造滋味滿溢的節慶。
      </p>
      <p>
        嚴選食材包括：豉油皇七頭大花竹蝦、北海道元貝、日本頂級蠔豉皇、一級烏禿海參、蠔皇鮑魚、南非花膠、米芝蓮鹵水鵝、招牌貴妃雞、鮑汁波蘭鵝掌、日本大花菇、家鄉春菜炆腩肉、鹵水豬手、汕頭手打白魚蛋、上湯津白、蘿蔔等。
      </p>
      <p>
        惠顧盆菜可以優惠價享用以下美食：「凍元朗楊氏烏頭」優惠價 $118、「鹵水鮮鵝肝」優惠價 $188、「鹵水鵝片」優惠價 $138。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
