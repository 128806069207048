import constants from '@/constants'
import colors from '@/theme/colors'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import NavTitle from './NavTitle'

NavBar.propTypes = {
  logo: PropTypes.bool,
  title: PropTypes.string,
  startButtons: PropTypes.arrayOf(PropTypes.node),
  endButtons: PropTypes.arrayOf(PropTypes.node),
  style: PropTypes.object,
}

/**
 * @component
 * @param {{
 * logo?: boolean
 * title?: string
 * startButtons?: Node[]
 * endButtons?: Node[]
 * style?: object
 * }} props
 */
export default function NavBar (props) {
  const { logo, title, startButtons, endButtons, style } = props
  const classes = useStyles(props)

  return (
    <div className={classes.navContainer} style={style}>
      <div className={classes.navbar}>
        <section className={clsx(classes.navSection, classes.navLeft)}>
          {startButtons}
        </section>
        <section className={classes.navSection}>
          <NavTitle logo={logo} title={title} />
        </section>
        <section className={clsx(classes.navSection, classes.navRight)}>
          {endButtons}
        </section>
      </div>
      <div className={classes.navbarBlur} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  navContainer: {
    zIndex: constants.app.zIndex.NAVBAR,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
  navbar: {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    height: constants.app.height.NAVBAR,
    backgroundColor: colors.secondaryOpacity70,
    boxShadow: theme.shadows[6],
    paddingLeft: 8,
    paddingRight: 8,
  },
  navbarBlur: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: constants.app.height.NAVBAR,
    backdropFilter: 'blur(6px)',
  },
  navSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navLeft: {
    flex: 1,
    flexShrink: 0,
    justifyContent: 'flex-start',
  },
  navRight: {
    flex: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
  },
}))
