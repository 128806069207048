import constants from '@/constants'
import { actions, useDispatch } from '@/redux'
import colors from '@/theme/colors'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

RestaurantCard.propTypes = {
  data: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disableMargin: PropTypes.bool,
  onClick: PropTypes.func,
}

/**
 * @component
 * @param {{
 * data: Object,
 * size?: 'small' | 'medium' | 'large'
 * disableMargin: boolean
 * onClick: Function
 * }} props
 */
export default function RestaurantCard (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { data, size = 'medium', disableMargin, onClick } = props

  const handleOpenLink = () => {
    if (_.includes(data.tags, '盆菜專區')) {
      const exception = ['幸壽司', '山下菓子', '元卓集團']
      if (!_.includes(exception, data.name)) {
        return dispatch(actions.app.toggleDialog('cny2021RestaurantDetail', data.name))
      }
    }
    window.open(data.link, '_blank')
  }

  return (
    <div
      className={clsx(
        classes.card,
        size === 'small' && classes.cardSizeSmall,
        size === 'medium' && classes.cardSizeMedium,
        size === 'large' && classes.cardSizeLarge,
        disableMargin && classes.disableMargin,
      )}
      style={{ backgroundImage: `url(${data.image})` }}
      onClick={onClick || handleOpenLink}
    >
      <Typography
        variant='h5'
        className={clsx(
          classes.cardTitle,
          size === 'small' && classes.cardTitleSmall,
          size === 'medium' && classes.cardTitleMedium,
          size === 'large' && classes.cardTitleLarge,
          disableMargin && classes.disableMargin,
        )}
      >
        {data.name}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 32,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  cardTitle: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    boxSizing: 'border-box',
    height: 72,
    color: colors.white,
    fontWeight: 300,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #333333 77.6%)',
    wordBreak: 'keep-all',
  },

  cardTitleSmall: {
    fontSize: '1.5rem',
    padding: 16,
  },
  cardTitleMedium: {
    fontSize: '1.8rem',
    padding: '12px 24px',
  },
  cardTitleLarge: {
    fontSize: '1.8rem',
    padding: 24,
  },
  cardSizeSmall: {
    height: 160,
    width: 160 * constants.app.CARD_ASPECT_RATIO,
    margin: 8,
  },
  cardSizeMedium: {
    height: 200,
    width: 200 * constants.app.CARD_ASPECT_RATIO,
    margin: 8,
  },
  cardSizeLarge: {
    height: 320,
    width: 320 * constants.app.CARD_ASPECT_RATIO,
    margin: 8,
  },
  disableMargin: {
    margin: 0,
  },
}))
