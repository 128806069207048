import Background from '@/campaigns/cny2021/assets/images/background.png'
import restaurants from '@/campaigns/cny2021/constants/restaurants'
import Content from '@/components/Content'
import FullpageDialog from '@/components/FullpageDialog'
import NavBar from '@/components/NavBar'
import NavIconButton from '@/components/NavBar/NavIconButton'
import { actions, useDispatch, useSelector } from '@/redux'
import { Dialog } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import Div100vh from 'react-div-100vh'
import Slider from 'react-slick'
import Datetime from './Datetime'
import Description from './Description'
import Hero from './Hero'
import Options from './Options'

/**
 * @component
 * @param {*} props
 */
export default function DetailDialog (props) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const handleClose = () => dispatch(actions.app.toggleDialog('cny2021RestaurantDetail', false))
  const title = useSelector(state => state.app.dialogs.cny2021RestaurantDetail)

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
  }

  function move (array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0])
  }

  const randomized = _.shuffle(restaurants)
  const currentIndex = _.findIndex(randomized, r => r.name === title)
  move(randomized, currentIndex, 0)

  const content = (
    <Slider {...settings} className={clsx(classes.slider)}>
      {_.map(randomized, (restaurant, index) => {
        return (
          <div key={index} className={clsx(classes.container)}>
            <NavBar
              style={{ position: 'absolute' }}
              title={restaurant.name}
              endButtons={[
                <NavIconButton
                  key='DetailDialog-end-0'
                  variant='close'
                  onClick={handleClose}
                  className={classes.closeIcon}
                />]}
            />
            <Content>
              <Hero image={restaurant.image} />
              <Description data={restaurant} />
              <Options options={restaurant.options} />
              <Datetime link={restaurant.link} />
            </Content>
          </div>
        )
      })}
    </Slider>
  )

  if (matches) {
    return (
      <Dialog
        open={Boolean(title)}
        onClose={handleClose}
        classes={{ paper: classes.background }}
      >
        {content}
      </Dialog>
    )
  }

  return (
    <FullpageDialog
      open={Boolean(title)}
      onClose={handleClose}
      classes={{ paper: classes.background }}
      transition='fade'
    >
      <Div100vh>
        {content}
      </Div100vh>
    </FullpageDialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    // flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  closeIcon: {
    fontSize: '1.4rem',
  },
  background: {
    backgroundImage: `url(${Background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
  },
}))
