import Content from '@/components/Content'
import FullpageDialog from '@/components/FullpageDialog'
import NavBar from '@/components/NavBar'
import NavButton from '@/components/NavBar/NavButton'
import NavIconButton from '@/components/NavBar/NavIconButton'
import RestaurantCard from '@/components/RestaurantCard'
import { actions, useDispatch, useSelector } from '@/redux'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function FilterResultDialogPage (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const restaurants = useSelector(state => state.app.restaurants)
  const filters = useSelector(state => state.app.filters)
  // const selectedAllTags = _.chain(filters).map(filter => filter.selected).flatMap().value().filter(tag => tag)
  const selectedPriceTags = filters.price.selected
  const selectedStyleTags = filters.style.selected
  const selectedRegionTags = filters.district.selected
  const selectedDiscountTags = filters.discount.selected
  const selectedOptionsTags = filters.options.selected
  const results = _.filter(restaurants, r => {
    // const diff = _.difference(selectedTags, [...r.tags, r.region]) // 找出 search 跟 target 之間的差異
    // return _.isEmpty(diff) // []
    // return selectedTags.length !== diff.length
    let priceResult = true
    let styleResult = true
    let regionResult = true
    let discountResult = true
    let optionsResult = true
    const priceTags = _.intersection(r.tags, filters.price.data)
    const styleTags = _.intersection(r.tags, filters.style.data)
    const regionTags = [r.region]
    const discountTags = _.intersection(r.tags, filters.discount.data)
    const optionsTags = _.intersection(r.tags, filters.options.data)
    if (!_.isEmpty(selectedPriceTags)) {
      const priceDiff = _.difference(selectedPriceTags, priceTags)
      if (selectedPriceTags.length === priceDiff.length) {
        priceResult = false
      }
    }
    if (!_.isEmpty(selectedStyleTags)) {
      const styleDiff = _.difference(selectedStyleTags, styleTags)
      if (selectedStyleTags.length === styleDiff.length) {
        styleResult = false
      }
    }
    if (!_.isEmpty(selectedRegionTags)) {
      const regionDiff = _.difference(selectedRegionTags, regionTags)
      if (selectedRegionTags.length === regionDiff.length) {
        regionResult = false
      }
    }
    if (!_.isEmpty(selectedDiscountTags)) {
      const discountDiff = _.difference(selectedDiscountTags, discountTags)
      if (selectedStyleTags.length === discountDiff.length) {
        discountResult = false
      }
    }
    if (!_.isEmpty(selectedOptionsTags)) {
      const optionsDiff = _.difference(selectedOptionsTags, optionsTags)
      if (!_.isEmpty(optionsDiff)) {
        optionsResult = false
      }
    }
    return priceResult && styleResult && regionResult && discountResult && optionsResult
  })
  const randomized = _.shuffle(results)

  const open = useSelector(state => state.app.pages.filterResult)
  const togglePage = (page) => dispatch(actions.app.togglePage('filterResult'))

  const goToPage = (page) => {
    dispatch(actions.app.resetPages())
    dispatch(actions.app.togglePage(page, true))
  }

  const resetFilter = () => {
    dispatch(actions.app.updateSearchFilter(null))
    dispatch(actions.app.resetSelectedFilters())
  }

  const handleResetFilter = () => {
    resetFilter()
    goToPage('filter')
  }

  return (
    <FullpageDialog open={open} onClose={togglePage}>
      <div className={classes.container}>
        <NavBar
          title='篩選'
          startButtons={[<NavButton key='FilterResultDialogPage-start-0' variant='back' onClick={togglePage} />]}
          endButtons={[
            <NavIconButton key='FilterResultDialogPage-end-0' variant='search' onClick={() => goToPage('search')} />,
            <NavIconButton key='FilterResultDialogPage-end-1' variant='resetFilter' onClick={handleResetFilter} />,
          ]}
        />
        <Content>
          <Grid container>
            {_.map(randomized, (result, index) => {
              return (
                <Grid item key={index} className={classes.gridItem} xs={6} sm={4} md={3} lg={2}>
                  <RestaurantCard data={result} size='medium' />
                </Grid>
              )
            })}
          </Grid>
        </Content>
      </div>
    </FullpageDialog>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitButton: {
    fontSize: '1.8rem',
    borderRadius: 800,
    padding: 16,
    marginTop: 32,
    marginBottom: 32,
  },
  shadow: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
  },
}))
