import CrossIcon from '@/assets/icons/custom/Cross'
import SearchIcon from '@/assets/icons/custom/Search'
import { matchHighlight } from '@/libs/string'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function SearchBar (props) {
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const restaurants = useSelector(state => state.app.restaurants)
  const searchInput = useSelector(state => state.app.searchInput)
  const handleInputChange = (e) => dispatch(actions.app.updateSearchInput(e.target.value))
  const handleOptionSelect = (value) => dispatch(actions.app.updateSearchInput(value))

  return (
    <div className={classes.searchBar}>
      <Autocomplete
        onChange={(e, value) => handleOptionSelect(value ? value.name : '')}
        className={classes.autocomplete}
        classes={{ listbox: classes.listbox, option: classes.option, paper: classes.paper }}
        fullWidth
        freeSolo
        options={restaurants}
        getOptionLabel={(option) => option.name}
        forcePopupIcon={false}
        closeIcon={<CrossIcon />}
        renderInput={(params) => (
          <TextField
            autoFocus
            value={searchInput}
            onChange={handleInputChange}
            className={classes.textField}
            {...params}
            placeholder='搜尋餐廳'
            variant='standard'
            margin='normal'
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.input },
              startAdornment: (
                <InputAdornment className={classes.startAdornment}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const parts = matchHighlight(option.name, inputValue)

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ color: part.highlight ? colors.primary : colors.gray3 }}>
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  searchBar: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  autocomplete: {
    width: '80%',
  },
  textField: {
    marginBottom: 0,
  },
  input: {
    fontSize: '1.8rem',
  },
  startAdornment: {
    marginRight: 8,
  },
  endAdornment: {
    padding: 0,
    marginRight: -30,
  },
  option: {
    width: '100%',
    fontSize: '1.8rem',
    borderBottom: '0.5px solid #DFDFDF',
  },
  listbox: {
    '& li:last-child': {
      borderBottom: 'none',
    },
  },
  paper: {
    backgroundColor: '#F7F7F7',
    boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.1)',
  },
}))
