import { SvgIcon } from '@material-ui/core'
import React from 'react'

export default function FilterList (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 21 14'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20.3462 1.3L0.666016 1.3L0.666016 0L20.3462 1.13249e-06V1.3ZM15.1968 13.7547L5.85566 13.7547V12.4547L15.1968 12.4547V13.7547ZM3.77969 7.52734L17.2724 7.52734V6.22734L3.77969 6.22734V7.52734Z' />
    </SvgIcon>
  )
}
