import ReadMoreButton from '@/components/ReadMoreButton'
import PropTypes from 'prop-types'
import React from 'react'

Taichungwah.propTypes = {
  readMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * readMore: boolean
 * onClick: Function
 * className?: string
 * }} props
 */
export default function Taichungwah (props) {
  const { readMore, onClick, className } = props

  if (!readMore) {
    return (
      <div className={className}>
        <p>
          大中華扎根於長沙灣，馳名於中式小菜，今年推出了一品盆菜系列，分別有3-5人份($688)，6-8人份($1088)，以及10-12人份($1680(包括¼隻燒鵝)，重點推介蠔...
          <ReadMoreButton readMore={readMore} onClick={onClick} />
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      <p>
        大中華扎根於長沙灣，馳名於中式小菜，今年推出了一品盆菜系列，分別有3-5人份($688)，6-8人份($1088)，以及10-12人份($1680(包括¼隻燒鵝)，重點推介蠔皇富貴鮑、圍村炆扣肉、紅燒海蔘等，讓你一試難忘。
        <ReadMoreButton readMore={readMore} onClick={onClick} />
      </p>
    </div>
  )
}
