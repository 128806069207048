import ArrowForwardSmall from '@/assets/icons/custom/ArrowForwardSmall'
import CheckIcon from '@/assets/icons/custom/Check'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/theme/colors'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

SubList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
}

/**
 * @component
 * @param {{
 * title: string
 * list: string[]
 * filter: string
 * }} props
 */
export default function SubList (props) {
  const { title, list, filter } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const selectedOptions = useSelector(state => state.app.filters[filter].selected)
  const isSelected = !_.chain(selectedOptions).intersection(list).isEmpty().value()
  const handleSelectItem = (option) => {
    dispatch(actions.app.updateFilterMultipleOptions(filter, option))
  }

  return (
    <List className={classes.root} classes={{ root: classes.noPadding }}>
      <ListItem button disableRipple onClick={toggleOpen}>
        <ListItemIcon className={clsx(classes.expandIcon, open && classes.collapseIcon)}>
          <ArrowForwardSmall stroke='#A5A5A5' classes={{ root: classes.arrowIcon }} />
        </ListItemIcon>
        <ListItemText primary={title} primaryTypographyProps={{ className: clsx(classes.listTitle, open && classes.listTitleOpen, !open && isSelected && classes.listTitleSetted) }} />
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {_.map(list, (item, index) => {
            const isSelected = _.includes(selectedOptions, item)
            return (
              <ListItem key={index} button disableRipple className={classes.nested} onClick={() => handleSelectItem(item)}>
                <ListItemText primary={item} primaryTypographyProps={{ className: clsx(classes.listItemText, isSelected && classes.listItemTextSelected) }} />
                {isSelected && (
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <CheckIcon strokecolor={colors.primary} />
                  </ListItemIcon>
                )}
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  noPadding: {
    padding: 0,
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  listTitle: {
    fontSize: '1.6rem',
  },
  listTitleOpen: {
    fontWeight: 700,
  },
  listTitleSetted: {
    color: colors.brown,
  },
  listItemText: {
    fontSize: '1.6rem',
  },
  listItemTextSelected: {
    color: colors.primary,
  },
  expandIcon: {
    minWidth: 0,
    marginRight: 8,
    transition: 'all 300ms ease',
  },
  collapseIcon: {
    transform: 'rotate(90deg)',
  },
  arrowIcon: {
    fontSize: '1rem',
  },
  listItemIcon: {
    minWidth: 0,
  },
}))
