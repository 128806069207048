import Chiulokyuen from './desc/Chiulokyuen'
import Choikee from './desc/Choikee'
import Daigongkun from './desc/Daigongkun'
import Epot from './desc/Epot'
import Gwaishifong from './desc/Gwaishifong'
import Happytogether from './desc/Happytogether'
import Kamlok from './desc/Kamlok'
import Kammuikee from './desc/Kammuikee'
import Lokcy from './desc/Lokcy'
import Peony from './desc/Peony'
import Prince from './desc/Prince'
import Serwongfun from './desc/Serwongfun'
import Steamfresh from './desc/Steamfresh'
import Taichungwah from './desc/Taichungwah'
import Threevirtues from './desc/Threevirtues'
import Tsochoi from './desc/Tsochoi'
import Tszmui from './desc/Tszmui'

export default [
  {
    name: '瑰私房',
    link: 'https://s.dimorder.com/cny_roseprivate',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648144/cny2021/popup/%E7%91%B0%E7%A7%81%E6%88%BF_y3g2yo.png',
    desc: Gwaishifong,
    reservation: '需於三天前預訂',
    options: [
      { name: '4人', price: 988 },
      { name: '6人', price: 1388 },
      { name: '10人', price: 1988 },
    ],
  },
  {
    name: '王子飯店',
    link: 'https://s.dimorder.com/cny_prince',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648117/cny2021/popup/%E7%8E%8B%E5%AD%90%E9%A3%AF%E5%BA%97_ik7sbv.png',
    desc: Prince,
    reservation: '需於一天前預訂',
    options: [
      { name: '6頭鮑魚(4位)', price: 1680, xs: 6 },
      { name: '6頭鮑魚(6位)', price: 2480, xs: 6 },
      { name: '升級盤菜(4位)', price: 2888, xs: 6 },
      { name: '升級盤菜(6位)', price: 4288, xs: 6 },
    ],
  },
  {
    name: '金妹記大排檔',
    link: 'https://s.dimorder.com/cny_goldmuikee',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648128/cny2021/popup/%E9%87%91%E5%A6%B9%E8%A8%98%E5%A4%A7%E6%8E%92%E6%AA%94_prjdv4.png',
    desc: Kammuikee,
    reservation: '需於三天前預訂',
    options: [
      { name: '6位', price: 988 },
    ],
  },
  {
    name: '金樂軒',
    link: 'https://s.dimorder.com/cny_kamlokhin',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648129/cny2021/popup/%E9%87%91%E6%A8%82%E8%BB%92_gvvk4k.png',
    desc: Kamlok,
    reservation: '需於一天前預訂',
    options: [
      { name: '3-4位', price: 788 },
      { name: '5-6位', price: 1188 },
      { name: '8-10位', price: 1488 },
    ],
  },
  {
    name: '九重鮮',
    link: 'https://s.dimorder.com/cny_steamfresh',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648125/cny2021/popup/%E4%B9%9D%E9%87%8D%E9%AE%AE_nwkqjg.png',
    desc: Steamfresh,
    reservation: '需於兩天前預訂',
    options: [
      { name: '4-6位', price: 2188 },
      { name: '10/2前早鳥優惠', price: 1898 },
    ],
  },
  // {
  //   name: '元卓集團',
  //   link: 'https://s.dimorder.com/cny_rtg',
  //   image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648131/cny2021/popup/%E6%99%82%E4%BB%A3%E5%86%B0%E5%AE%A4_feympt.png',
  //   desc: Roundtable,
  //   reservation: '需於三天前預訂',
  //   options: [
  //     { name: '大鵬展翅6人', price: 538 },
  //     { name: '雙龍出海6人', price: 638 },
  //     { name: '陸地最強6人', price: 688 },
  //   ],
  // },
  {
    name: '財記美食',
    link: 'https://s.dimorder.com/cny_choikei',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611753522/cny2021/popup/%E8%B2%A1%E8%A8%98_volefs.png',
    desc: Choikee,
    reservation: '需於兩天前預訂',
    options: [
      { name: '4-6人', price: 888 },
      { name: '6-12人', price: 1388 },
    ],
  },
  {
    name: '三德素食（北角）',
    link: 'https://s.dimorder.com/cny_threevirtuesjd',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648114/cny2021/popup/%E4%B8%89%E5%BE%B7%E7%B4%A0%E9%A3%9F_wgrgpr.png',
    desc: Threevirtues,
    reservation: '需於兩天前預訂',
    options: [
      { name: '6位', price: 688 },
      { name: '10-12位', price: 1288 },
    ],
  },
  {
    name: '三德素食（佐敦）',
    link: 'https://s.dimorder.com/cny_threevirtuesnp',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648114/cny2021/popup/%E4%B8%89%E5%BE%B7%E7%B4%A0%E9%A3%9F_wgrgpr.png',
    desc: Threevirtues,
    reservation: '需於兩天前預訂',
    options: [
      { name: '6位', price: 688 },
      { name: '10-12位', price: 1288 },
    ],
  },
  {
    name: '慈妹大排檔',
    link: 'https://s.dimorder.com/cny_tszmui',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648139/cny2021/popup/%E6%85%88%E5%A6%B9_civo8d.png',
    desc: Tszmui,
    reservation: '需於兩天前預訂',
    options: [
      { name: '6-8人', price: 867 },
      { name: '10-12人', price: 1197 },
    ],
  },
  {
    name: '蛇王芬',
    link: 'https://s.dimorder.com/cny_serwongfun',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648134/cny2021/popup/%E8%9B%87%E7%8E%8B%E8%8A%AC_sahku3.png',
    desc: Serwongfun,
    reservation: '需於一天前預訂',
    options: [
      { name: '4位', price: 1688 },
    ],
  },
  {
    name: '粗菜館',
    link: 'https://s.dimorder.com/cny_tsochoi',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648135/cny2021/popup/%E7%B2%97%E8%8F%9C%E9%A4%A8_hzdj9f.png',
    desc: Tsochoi,
    reservation: '需於三天前預訂',
    options: [
      { name: '4位', price: 888 },
      { name: '6位', price: 1088 },
    ],
  },
  {
    name: '大公館',
    link: 'https://s.dimorder.com/cny_createrchinaclub',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648138/cny2021/popup/%E5%A4%A7%E5%85%AC%E9%A4%A8_cfwdft.png',
    desc: Daigongkun,
    reservation: '需於一天前預訂',
    options: [
      { name: '4位', price: 2388 },
      { name: '另加每位', price: 597 },
    ],
  },
  {
    name: '大中華飯店',
    link: 'https://s.dimorder.com/cny_taichungwah',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648114/cny2021/popup/%E5%A4%A7%E4%B8%AD%E8%8F%AF%E9%A3%AF%E5%BA%97_uuogb2.png',
    desc: Taichungwah,
    reservation: '需於一星期前預訂',
    options: [
      { name: '3-5人', price: 688 },
      { name: '6-8人', price: 1088 },
      { name: '10-12人', price: 1680 },
    ],
  },
  {
    name: '同撈同煲',
    link: 'https://s.dimorder.com/cny_happytogether',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648123/cny2021/popup/%E5%90%8C%E6%92%88%E5%90%8C%E7%85%B2_bhx68j.png',
    desc: Happytogether,
    reservation: '需於一天前預訂',
    options: [
      { name: '迷你盤菜4人', price: 988, xs: 6 },
      { name: '升級版豪華迷你版盤4人', price: 1188, xs: 6 },
      { name: '豪華版細盤6-8人', price: 1288, xs: 6 },
      { name: '升級版豪華細盤6-8人', price: 1688, xs: 6 },
      { name: '大盤12人份量', price: 1888, xs: 6 },
      { name: '升級版豪華大盤12人', price: 2688, xs: 6 },
    ],
  },
  {
    name: '遊園',
    link: 'https://s.dimorder.com/cny_peony',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648137/cny2021/popup/%E9%81%8A%E5%9C%92_qjfys5.png',
    desc: Peony,
    options: [
      { name: '一品煲(四位用)', price: 688, xs: 6 },
    ],
  },
  {
    name: '樂翠園海鮮菜館',
    link: 'https://s.dimorder.com/cny_lokchuiyuen',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611648149/cny2021/popup/%E6%A8%82%E7%BF%A0%E5%9C%92_eaprnm.png',
    desc: Lokcy,
    reservation: '需於一天前預訂',
    options: [
      { name: '', price: 536 },
    ],
  },
  {
    name: '一鍋堂',
    link: 'https://s.dimorder.com/cny_epot',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1611809101/cny2021/popup/%E4%B8%80%E9%8D%8B%E5%A0%82_apzbj1.png',
    desc: Epot,
    reservation: '需於三天前預訂',
    options: [
      { name: '家庭裝 4人', price: 608 },
      { name: '中盆裝 6人', price: 898 },
      { name: '大盆裝 10人', price: 1388 },
    ],
  },
  {
    name: '潮樂園',
    link: 'https://s.dimorder.com/chiulokyuen',
    image: 'https://res.cloudinary.com/dimorderd/image/upload/v1612524078/cny2021/popup/%E6%BD%AE%E6%A8%82%E5%9C%92_ikjnfl.png',
    desc: Chiulokyuen,
    reservation: '需於兩天前預訂',
    options: [
      { name: '6-8位用', price: 1388 },
      { name: '10-12 位用', price: 2288 },
    ],
  },
]
