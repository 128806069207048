import facebookIcon from '@/assets/icons/socialMedia/facebook.svg'
import lineIcon from '@/assets/icons/socialMedia/line.svg'
import whatsappIcon from '@/assets/icons/socialMedia/whatsapp.svg'
import constants from '@/constants'
import { actions, useDispatch, useSelector } from '@/redux'
import { Box, Card, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CrossIcon from '@material-ui/icons/Close'
import React from 'react'
import { FacebookShareButton, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappShareButton } from 'react-share'

export default function Share (props) {
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const open = useSelector(state => state.app.dialogs.share)
  const handleClose = () => dispatch(actions.app.toggleDialog('share', false))

  const shareUrl = 'http://s.dimorder.com/Eu3kLm5ME'
  const message = '我啱啱喺 「點單-跨區外送美食指南」搵到心愛餐廳,你不如一齊睇下~'

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      classes={{ paper: classes.paperRoot, paperFullWidth: classes.paperFullWidth }}
    >
      <Card className={classes.card}>
        <IconButton className={classes.closeButton} aria-label='close' onClick={handleClose}>
          <CrossIcon />
        </IconButton>
        <DialogContent className={classes.titleTxt}>
          快啲分享俾朋友知!
        </DialogContent>
        <Box display='flex' flexDirection='row' justifyContent='space-around'>
          <FacebookShareButton url={shareUrl} quote={message} onClick={handleClose}>
            <img src={facebookIcon} alt='facebook_icon' />
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl} title={message} onClick={handleClose}>
            <img src={whatsappIcon} alt='whatsapp_icon' />
          </WhatsappShareButton>
          <LineShareButton url={shareUrl} title={message} onClick={handleClose}>
            <img src={lineIcon} alt='line_icon' />
          </LineShareButton>
          <TelegramShareButton url={shareUrl} title={message} onClick={handleClose}>
            <TelegramIcon size={30} round />
          </TelegramShareButton>
        </Box>
      </Card>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  paperFullWidth: {
    width: '100%',
    minWidth: '100%',
  },
  titleTxt: {
    position: 'relative',
    top: -10,
    textAlign: 'center',
    fontSize: 18,
  },
  paperRoot: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    margin: 0,
    marginTop: constants.app.height.NAVBAR,
  },
  share: {
    position: 'fixed',
    top: 40,
    left: 0,
    height: 'calc(100vh - 40px)',
    width: '100vw',
    backgroundColor: '#00000066',
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    padding: '16px 24px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 8,
  },
}))
