import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

/**
 * @component
 * @param {*} props
 */
export default function Section (props) {
  const { children, className } = props
  const classes = useStyles(props)

  return (
    <section className={clsx(classes.section, className)}>
      {children}
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  section: {
    padding: 8,
    margin: '0px 4px',
    fontSize: '1.5rem',
  },
}))
