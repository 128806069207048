import BookmarkIcon from '@/assets/icons/custom/Bookmark'
import CrossIcon from '@/assets/icons/custom/Cross'
import FilterListIcon from '@/assets/icons/custom/FilterList'
import SearchIcon from '@/assets/icons/custom/Search'
import ShareIcon from '@/assets/icons/custom/Share'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

NavIconButton.propTypes = {
  variant: PropTypes.oneOf(['search', 'filter', 'resetFilter', 'close', 'share', 'bookmark']),
  onClick: PropTypes.func,
  className: PropTypes.string,
}

/**
 * @component
 * @param {{
 * variant: 'search' | 'filter' | 'resetFilter' | 'close' | 'bookmark'
 * onClick: Funcion
 * className: string
 * }} props
 */
export default function NavIconButton (props) {
  const { variant, onClick, className } = props
  const classes = useStyles(props)

  let icon
  switch (variant) {
    case 'search': {
      icon = <SearchIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    case 'filter': {
      icon = <FilterListIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    case 'resetFilter': {
      icon = <FilterListIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    case 'close': {
      icon = <CrossIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    case 'share': {
      icon = <ShareIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    case 'bookmark': {
      icon = <BookmarkIcon fontSize='large' classes={{ fontSizeLarge: classes.icon }} />
      break
    }
    default:
      break
  }

  return (
    <IconButton
      size='medium'
      className={clsx(classes.button, className)}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: 8,
    margin: 8,
    fontSize: '1.8rem',
  },
  icon: {
    fontSize: 'inherit',
  },
}))
